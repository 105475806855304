/* eslint-disable unused-imports/no-unused-imports */
import { Close } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Dialog, Grid } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { SecondaryDefaultButton } from "../../../components/common/button/ButtonStyle";
import { useGetWindowSize } from "../../../components/common/hook/WindowSize";
import { Text } from "../../../components/common/typography/Style";
import LoginModal from "../../../components/header/LoginModal";
import { _url } from "../../../config/utils";
import { TOGGLE_LOGIN_MODAL, logoutUser } from "../../../redux/modules/auth";
import { ColorTheme } from "../../../styles/color";
import RedesignFooter from "../footer/Footer";
import AccountButton from "./AccountButton";
import MobileUserInfo from "./MobileUserInfo";
// import { IS_USA_SITE } from "../../../constants";
// import { MHAnnouncementBar } from "../promotions/malaysia-airlines/MHAnnouncementBar";

const navItems = [
    {
        label: "Testimonials",
        type: "internal",
        url: "/testimonial"
    },
    {
        label: "About Us",
        type: "internal",
        url: "/aboutus"
    },
    {
        label: "How it works",
        type: "internal",
        url: "/how-it-works"
    },
    {
        label: "FAQ",
        type: "external",
        url: "https://help.paylatertravel.com/"
    }
];

function Navbar(props) {
    const { windows } = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const navigate = useNavigate();
    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };
    const location = useLocation();
    const loginModal = useSelector((state) => state.auth.loginModal);
    const { mobileView } = useGetWindowSize();
    const listenScrollEvent = (event) => {
        if (window.scrollY < 73) {
            return setIsScrolled(false);
        } else if (window.scrollY > 70) {
            return setIsScrolled(true);
        }
    };
    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);

        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    const drawer = () => (
        <Box>
            <Grid p={"16px"} flexWrap={"nowrap"} container justifyContent={"space-between"}>
                <img
                    alt="logo"
                    width="160px"
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                    src={
                        mobileView || isScrolled || !props.showTransparentNavbar
                            ? _url("assets/images/plt-blue-logo.png")
                            : _url("assets/images/plt-white-logo.png")
                    }
                />
                <IconButton
                    color="inherit"
                    edge="start"
                    onClick={() => {
                        handleDrawerToggle();
                    }}
                    sx={{ display: { sm: "none" } }}
                >
                    <Close style={{ color: ColorTheme.base.black }} />
                </IconButton>
            </Grid>
            {props.auth.isAuthenticated && (
                <MobileUserInfo
                    auth={props.auth}
                    logoutUser={() => {
                        dispatch(logoutUser());
                    }}
                />
            )}

            <Divider />
            <List>
                {navItems.map((item, index) => (
                    <ListItem key={item.label} disablePadding>
                        <ListItemButton
                            sx={{
                                borderLeft: window.location.pathname.includes(item.url)
                                    ? `4px solid ${ColorTheme.secondary[500]}`
                                    : ""
                            }}
                        >
                            <Text
                                size={"md"}
                                textweight={"medium"}
                                onClick={() => {
                                    if (item.type === "external") {
                                        window.location.href = item.url;
                                        return;
                                    }
                                    navigate(item.url);
                                    setMobileOpen(false);
                                }}
                                width={"100%"}
                                py={"20px"}
                                textcolor={
                                    window.location.pathname.includes(item.url)
                                        ? ColorTheme.secondary[600]
                                        : ColorTheme.grayNeutral[700]
                                }
                                borderBottom={
                                    index !== navItems.length - 1 ? `1px ${ColorTheme.grayNeutral[200]} solid` : ""
                                }
                            >
                                {item.label}
                            </Text>
                        </ListItemButton>
                    </ListItem>
                ))}
                <ListItem style={{ justifyContent: "center" }}>
                    {props.auth.isAuthenticated ? (
                        <AccountButton
                            logoutUser={() => {
                                dispatch(logoutUser());
                            }}
                            isScrolled={isScrolled || !props.showTransparentNavbar}
                            auth={props.auth}
                        />
                    ) : (
                        <SecondaryDefaultButton
                            onClick={() => {
                                handleDrawerToggle();
                                dispatch({
                                    type: TOGGLE_LOGIN_MODAL
                                });
                            }}
                            style={{ width: "141px" }}
                        >
                            Login
                        </SecondaryDefaultButton>
                    )}
                </ListItem>
            </List>
        </Box>
    );

    const container = windows !== undefined ? () => window().document.body : undefined;

    const path = location.pathname;
    return (
        <>
            <Box sx={{ display: "flex" }}>
                <CssBaseline />
                <AppBar
                    sx={{
                        background: mobileView || isScrolled || !props.showTransparentNavbar ? "white" : "inherit",
                        boxShadow: "none",
                        position: mobileView ? "sticky" : "fixed"
                    }}
                    component="nav"
                >
                    {/* BANNER CODE*/}
                    {/* {path === "/" && !IS_USA_SITE && (<MHAnnouncementBar />)} */}
                    {/* BANNER CODE*/}
                    <Toolbar>
                        <Grid p={"16px"} flexWrap={"nowrap"} container justifyContent={"space-between"}>
                            <img
                                alt="logo"
                                width="160px"
                                onClick={() => navigate("/")}
                                style={{ cursor: "pointer" }}
                                src={
                                    mobileView || isScrolled || !props.showTransparentNavbar
                                        ? _url("assets/images/plt-blue-logo.png")
                                        : _url("assets/images/plt-white-logo.png")
                                }
                            />
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ display: { sm: "none" } }}
                            >
                                <MenuIcon style={{ color: ColorTheme.base.black }} />
                            </IconButton>
                            <Box alignItems={"center"} display={["none", "none", "flex"]} gap={"24px"}>
                                {navItems.map((item) => (
                                    <Text
                                        size={"md"}
                                        textweight={"medium"}
                                        style={{ cursor: "pointer" }}
                                        key={item.label}
                                        onClick={() => {
                                            if (item.type === "external") {
                                                window.location.href = item.url;
                                                return;
                                            }
                                            navigate(item.url);
                                            setMobileOpen(false);
                                        }}
                                        textcolor={
                                            isScrolled || !props.showTransparentNavbar
                                                ? ColorTheme.base.black
                                                : ColorTheme.base.white
                                        }
                                    >
                                        {item.label}
                                    </Text>
                                ))}
                                {!props.auth.isAuthenticated ? (
                                    <Box mt={["28px", "28px", "0px"]}>
                                        <SecondaryDefaultButton
                                            onClick={() => {
                                                dispatch({
                                                    type: TOGGLE_LOGIN_MODAL
                                                });
                                            }}
                                            style={{ width: "141px" }}
                                        >
                                            Login
                                        </SecondaryDefaultButton>
                                    </Box>
                                ) : (
                                    <AccountButton
                                        logoutUser={() => {
                                            dispatch(logoutUser());
                                        }}
                                        isScrolled={isScrolled}
                                        auth={props.auth}
                                    />
                                )}
                            </Box>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <nav>
                    <Dialog open={mobileOpen} fullScreen onClose={handleDrawerToggle}>
                        {drawer()}
                    </Dialog>
                </nav>
                <LoginModal
                    toggleLoginModal={() =>
                        dispatch({
                            type: TOGGLE_LOGIN_MODAL
                        })
                    }
                    isOpen={loginModal}
                />
            </Box>
            {props.children && (
                <Grid container pt={["0px", "0px", "56px"]}>
                    <Grid minHeight={"100vh"} item xs={12}>
                        {props.children}
                    </Grid>
                    <Grid item xs={12}>
                        <RedesignFooter />
                    </Grid>
                </Grid>
            )}
        </>
    );
}

export default Navbar;
